import React, { Component } from 'react'
import { Map, TileLayer, Marker } from 'react-leaflet'

export default class ContactMap extends Component {
  render() {
    const props = this.props;
    const position = [props.lat, props.lng];
    const zoom = props.zoom;
    const controls = props.controls

    if (typeof window !== 'undefined') {
      return (
          <section className="hero" id="container">
            <Map center={position} zoom={zoom} style={{zIndex: 0, height: '350px',}} zoomControl={controls} dragging={controls} doubleClickZoom={controls} scrollWheelZoom={controls} attributionControl={false} >
                <TileLayer
                url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
                />
                <Marker position={position} />
            </Map>
          </section>
      )
    }
    return null
  }
}